<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <div
          v-if="users.length > 0"
          class="card card-custom gutter-b card-stretch"
        >
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("USER.ALL") }}
                <span class="text-muted pt-2 font-size-sm d-block">{{
                  $t("USER.LIST")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <div class="mr-2">
                <button
                  @click.prevent="exportXLSX"
                  type="button"
                  class="btn btn-light-primary font-weight-bolder"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  disabled
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="media/svg/icons/Files/Export.svg" />
                  </span>
                  Export to .xlsx
                </button>
              </div>
              <!--end::Dropdown-->

              <!--begin::Button-->
              <router-link
                to="/users/new"
                class="btn btn-primary font-weight-bolder"
                ><span class="svg-icon svg-icon-md">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                {{ $t("USER.NEW_USER") }}</router-link
              >
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body pt-10">
            <div v-if="isUsersLoaded" class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          <span>{{ $t("GENERAL.FIRST_LAST") }}</span>
                        </th>
                        <th style="min-width: 130px">
                          <span>{{ $t("GENERAL.EMAIL") }}</span>
                        </th>
                        <th style="min-width: 150px">{{ $t("USER.ROLE") }}</th>
                        <th style="min-width: 150px"></th>
                        <th style="min-width: 170px" class="pr-7 text-right">
                          {{ $t("MENU.ACTIONS") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(user, key) in users" :key="key">
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              <router-link
                                :to="'/users/edit/' + user.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ user.firstName }}
                                {{ user.lastName }}</router-link
                              >
                            </div>
                          </div>
                        </td>
                        <td :class="{ user: key === 0 }">
                          <a
                            :href="'mailto:' + user.email"
                            class="text-dark-75 text-hover-primary"
                            >{{ user.email }}</a
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="user.roles[0] === 'ROLE_SUPER_ADMIN'"
                            class="label label-lg label-light-danger label-inline"
                            >Super Admin</span
                          >
                          <span
                            v-if="user.roles[0] === 'ROLE_ADMIN'"
                            class="label label-lg label-light-success label-inline"
                            >Admin</span
                          >
                          <span
                            v-if="user.roles[0] === 'ROLE_BABY_ADMIN'"
                            class="label label-lg label-light-primary label-inline"
                            >Baby Admin</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }"></td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <router-link
                            :to="'/users/edit/' + user.id"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Design/Edit.svg"
                                title="Edit"
                                aria-label="Edit"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeUser(user.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ListUsers",
  data() {
    return {
      users: [],
      isUsersLoaded: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "All Users", route: "/users/list" },
    ]);

    ApiService.get("users").then(({ data }) => {
      this.users = data["hydra:member"];
      this.isUsersLoaded = true;
    });
  },

  methods: {
    exportXLSX() {
      ApiService.get("users/export").then(({ data }) => {
        if (data.status === "ok") {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("USER.EXPORT_SUCCESS"),
          });
          window.open(data.downloadUrl, "_blank").focus();
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("USER.EXPORT_ERROR"),
          });
        }
      });
    },
    removeUser(id) {
      const confirmation = confirm(this.$t("USER.DELETE_CONFIRMATION"));
      if (confirmation === false) return;

      ApiService.delete("users/" + id)
        .then(({ status }) => {
          if (status === 204) {
            this.users = this.users.filter((product) => product.id !== id);
            this.$notify({
              group: "notification",
              type: "error",
              title: this.$t("USER.DELETE_SUCCESS"),
            });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "notification",
            type: "error",
            title: error,
          });
        });
    },
  },
};
</script>
